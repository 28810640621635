import { useState, useEffect }   from 'react'
import GameHeader                from '../../components/GameHeader/GameHeader'
import GameIcon                  from './resources/GameIcon/GameIcon'
import ShowResult                from './resources/ShowResult/ShowResult'
import css                       from './RockPaperScissorsLizardSpock.module.css'

const choices = ['rock', 'paper', 'scissors', 'lizard', 'spock']

const GameRules = ()=> (
  <>
    <p>
      Taken directly from <strong>The Big Bang Theory</strong>, the rules are as follows:
    </p>
    <ul className={css.rulesList}>
      <li>Scissors cuts Paper</li>
      <li>Paper covers Rock</li>
      <li>Rock crushes Lizard</li>
      <li>Lizard poisons Spock</li>
      <li>Spock smashes Scissors</li>
      <li>Scissors decapitates Lizard</li>
      <li>Lizard eats Paper</li>
      <li>Paper disproves Spock</li>
      <li>Spock vaporizes Rock</li>
      <li><em>(and as it always has)</em> Rock crushes Scissors</li>
    </ul>
  </>
)

const SystemGame = ({ choice })=> (
  choices.map(systemChoice=> (
    <GameIcon 
      activeColor='#c22511'
      key={systemChoice}
      icon={systemChoice} 
      enabled={false} 
      visible={!choice || choice === systemChoice}
      active={choice && choice === systemChoice}
    />
  ))
)

const RockPaperScissorsLizardSpock = ()=> {

  const [playerSelection, setPlayerSelection] = useState('')
  const [systemSelection, setSystemSelection] = useState('')

  useEffect(()=> {
    if (!playerSelection) return
    const number = Math.floor(Math.random()*5)
    const choice = choices[number]
    setSystemSelection(choice)
  }, [playerSelection])

  const handleResetGame = ()=> {
    setPlayerSelection('')
    setSystemSelection('')
  }

  return (
    <>
      <GameHeader 
        title='Rock Paper Scissors Lizard Spock'
        rules={<GameRules />}
        options={[]}
        onReset={handleResetGame}
      />
      <div className={css.main}>
        <div className={css.game}>
          <div className={css.system}>
            <h3 className={css.systemTitle}>Computer</h3>
            <SystemGame choice={systemSelection} />
          </div>
          <div className={css.result}>
            <ShowResult playerSelection={playerSelection} systemSelection={systemSelection} />
          </div>
          <div className={css.player}>
            <h3 className={css.playerTitle}>You</h3>
            {choices.map(choice=> (
              <GameIcon 
                activeColor='#2467c2'
                key={choice}
                icon={choice} 
                onClick={()=> setPlayerSelection(choice)} 
                enabled={!playerSelection} 
                visible={!playerSelection || playerSelection === choice}
                active={playerSelection === choice}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default RockPaperScissorsLizardSpock