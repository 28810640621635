
import { useEffect, useState }                                                      from 'react'
import GameHeader                                                                   from '../../components/GameHeader/GameHeader'
import { canKnightJumpThere, generateRandomBoardPosition, getKnightRemainingMoves } from './resources/chess'
import ChessBoard                                                                   from './resources/ChessBoard/ChessBoard'
import css                                                                          from './TheJumpingKnight.module.css'

const GameRules = ()=> (
  <>
    <p>
      The <strong>knight</strong> is probably my favorite piece in the game of chess. 
      It can move in an L shape, two squares in one direction and one square in the other direction.
    </p>
    <p>
      It is therefore possible to place a knight on a chess board and jump to every single square
      without visiting the same square twice. But it's quite the challenge!
    </p>
    <p>
      Are you up for it? Move the knight to start!
    </p>
  </>
)

const TheJumpingKnight = () => {

  const [visited, setVisited] = useState([])

  const [knight, setKnight] = useState({})

  useEffect(()=> {
    const { row, column } = generateRandomBoardPosition()
    setKnight({ row, column })
    setVisited([{ row, column }])
  }, [])

  const remainingMoves = getKnightRemainingMoves({ row: knight.row, column: knight.column, visited })

  const squaresRemaining = 64 - visited.length
  const isSolved = squaresRemaining === 0
  const isStuck = remainingMoves.length === 0 && !isSolved && visited.length > 0

  const handleBoardClick = ({ row, column })=> {
    const validJump = canKnightJumpThere({
      start: { row: knight.row, column: knight.column },
      end: { row, column }
    })
    const alreadyVisited = visited.find(v=> v.row === row && v.column === column)
    if (!validJump || alreadyVisited) return
    setKnight({ row, column })
    setVisited(v=> ([...v, { row, column }]))
  }

  const handleResetBoard = ()=> {
    const { row, column } = generateRandomBoardPosition()
    setKnight({ row, column })
    setVisited([])
  }

  return (
    <>
      <GameHeader 
        title='The Jumping Knight' 
        rules={<GameRules />}
        options={[]}
        onReset={handleResetBoard}
      />
      <div className={css.main}>
        <div className={css.intro}>
          {isSolved && (
            <p className={css.successText}>
              <strong className={css.successTitle}>You did it! 🎉🎉🎉</strong> 
              The knight covered the whole board!
            </p>
          )}
          {isStuck && (
            <p className={css.failureText}>
              <strong className={css.failureTitle}>Oh no! 😱😱😱</strong>
              <span>
                The knight can't move any further.{' '}
                <span className={css.resetLink} onClick={handleResetBoard}>Try again?</span>
              </span>
            </p>
          )}
          {!isStuck && squaresRemaining > 0 && (
            <p className={css.progressText}>
              Keep going!{' '}<strong>{squaresRemaining}</strong>{' '}squares remaining
            </p>
          )}
        </div>
        <ChessBoard
          visited={visited}
          knight={knight}
          onClick={handleBoardClick}
        />
      </div>
    </>
  )

}

export default TheJumpingKnight