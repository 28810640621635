const getSquare = (board, row, column)=> {
  const square_size = Math.sqrt(board.length)
  let square = []
  const start_row = Math.floor(row / square_size) * square_size
  const start_col = Math.floor(column / square_size) * square_size
  for (let i=0; i<square_size; i++) {
    for (let j=0; j<square_size; j++) {
      square = [ ...square, board[start_row+i][start_col+j] ]
    }
  }
  return square
}

const getRow = (board, row, column)=> {
  return board[row]
}

const getColumn = (board, row, column)=> {
  let column_data = []
  for (let i=0; i<board.length; i++) {
    column_data = [ ...column_data, board[i][column] ]
  }
  return column_data
}

const isSectionValid = board_data=> {
  const min = 1
  const max = board_data.length
  for (let i=0; i<max; i++) {
    const element = board_data[i]
    if (!element) continue
    if (element < min || element > max) return false
    for (let j=i+1; j<max; j++) {
      if (board_data[j] === element) return false
    }
  }
  return true
}

const getEmptyCell = board=> {
  for (let i=0; i<board.length; i++) {
    for (let j=0; j<board.length; j++) {
      if (!board[i][j]) return [i, j]
    }
  }
  return [null, null]
}

const setCell = (board, row_index, column_index, value)=> {
  return board.map((row, r_index)=> {
    if (r_index !== row_index) return [...row]
    return row.map((cell, c_index)=> {
      if (c_index === column_index) return value
      return cell
    })
  })
}

const solveSudoku = board=> {
  const [empty_row, empty_column] = getEmptyCell(board)
  if (empty_row === null || empty_column === null) return board
  for (let i=1; i<=board.length; i++) {
    const updated_board = setCell(board, empty_row, empty_column, i)
    const changed_square = getSquare(updated_board, empty_row, empty_column)
    const changed_row = getRow(updated_board, empty_row, empty_column)
    const changed_column = getColumn(updated_board, empty_row, empty_column)
    // If invalid change, undo it & try the next candidate
    if (!isSectionValid(changed_square) || !isSectionValid(changed_row) || !isSectionValid(changed_column)) {
      continue
    }
    // Attempt to solve the board with this change
    const solved_board = solveSudoku(updated_board)
    if (solved_board) return solved_board
  }
  return null
}

const reverseSolveSudoku = board=> {
  const [empty_row, empty_column] = getEmptyCell(board)
  if (empty_row === null || empty_column === null) return board
  for (let i=board.length; i>=1; i--) {
    const updated_board = setCell(board, empty_row, empty_column, i)
    const changed_square = getSquare(updated_board, empty_row, empty_column)
    const changed_row = getRow(updated_board, empty_row, empty_column)
    const changed_column = getColumn(updated_board, empty_row, empty_column)
    // If invalid change, undo it & try the next candidate
    if (!isSectionValid(changed_square) || !isSectionValid(changed_row) || !isSectionValid(changed_column)) {
      continue
    }
    // Attempt to solve the board with this change
    const solved_board = reverseSolveSudoku(updated_board)
    if (solved_board) return solved_board
  }
  return null
}

const solveSudokuWithOffset = board=> {
  const [empty_row, empty_column] = getEmptyCell(board)
  if (empty_row === null || empty_column === null) return board
  const random_offset = Math.floor(Math.random()*board.length)
  for (let i=1; i<=board.length; i++) {
    const updated_board = setCell(board, empty_row, empty_column, ((i+random_offset)%board.length) + 1)
    const changed_square = getSquare(updated_board, empty_row, empty_column)
    const changed_row = getRow(updated_board, empty_row, empty_column)
    const changed_column = getColumn(updated_board, empty_row, empty_column)
    // If invalid change, undo it & try the next candidate
    if (!isSectionValid(changed_square) || !isSectionValid(changed_row) || !isSectionValid(changed_column)) {
      continue
    }
    // Attempt to solve the board with this change
    const solved_board = solveSudoku(updated_board)
    if (solved_board) return solved_board
  }
  return null
}

export const areBoardsEqual = (board_1, board_2)=> {
  for (let i=0; i<board_1.length; i++) {
    for (let j=0; j<board_1.length; j++) {
      if (board_1[i][j] !== board_2[i][j]) return false
    }
  }
  return true
}

export const generateRandomSudokuBoard = (size=9)=> {
  // Step 1: Generate a "random" "seed" board
  while (true) {
    const board = new Array(size).fill(null).map(()=> new Array(size).fill(null))
    for (let i=1; i<size+1; i++) {
      const random_row = Math.floor(Math.random()*size)
      const random_column = Math.floor(Math.random()*size)
      // If the square is taken, go again
      if (board[random_row][random_column]) {
        i--
        continue
      }
      board[random_row][random_column] = i
    }
    // Step 2: Fill it "randomly"
    const solved_board = solveSudokuWithOffset(board)
    if (!solved_board) continue
    return solved_board
  }
}

export const generateSudokuPuzzle = full_board=> {
  const numbers_to_remove = Math.floor(0.6 * full_board.length * full_board.length)
  let current_board = full_board
  for (let i=0; i<numbers_to_remove; i++) {
    const random_row = Math.floor(Math.random()*full_board.length)
    const random_column = Math.floor(Math.random()*full_board.length)
    if (!current_board[random_row][random_column]) {
      i--
      continue
    }
    const emptier_board = setCell(current_board, random_row, random_column, null)
    const small_solution = solveSudoku(emptier_board)
    const large_solution = reverseSolveSudoku(emptier_board)
    if (areBoardsEqual(small_solution, large_solution)) {
      current_board = emptier_board
    }
    else i--
  }
  return current_board
}