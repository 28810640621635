import { useCallback, useEffect, useState }     from 'react'
import GameHeader                               from '../../components/GameHeader/GameHeader'
import css                                      from './LightSwitch.module.css'

const Lightbulb = ()=> (
  <svg className={css.lightbulb} viewBox="0 0 459.562 459.562">
    <path d="M229.781,0c-81.569,0-147.93,63.047-147.93,140.543c0,54.028,20.928,84.706,41.178,114.388
      c16.882,24.736,34.339,50.313,35.394,84.912c0.26,8.539,6.353,15.552,14.384,17.289c-7.475,2.291-12.912,9.244-12.912,17.47
      c0,7.247,4.23,13.489,10.347,16.442c-6.116,2.954-10.347,9.196-10.347,16.444c0,10.091,8.18,18.271,18.271,18.271h11.167
      c3.45,19.216,20.24,33.801,40.448,33.801c20.208,0,37-14.585,40.449-33.801h11.166c10.092,0,18.271-8.181,18.271-18.271
      c0-7.248-4.23-13.49-10.348-16.444c6.117-2.953,10.348-9.195,10.348-16.442c0-8.189-5.389-15.12-12.812-17.44
      c8.025-1.742,14.113-8.752,14.375-17.288c1.053-34.586,18.482-60.163,35.354-84.922c20.221-29.668,41.127-60.346,41.127-114.408
      C377.71,63.047,311.351,0,229.781,0z M306.371,234.396c-15.518,22.771-34.314,50.355-40.014,86.645l-18.83-0.005l26.897-153.13
      c0.015-0.083,0.028-0.166,0.042-0.249c1.43-9.385-1.296-18.896-7.479-26.093c-5.983-6.964-14.709-11.121-23.342-11.121
      c-4.852,0-9.609,1.318-13.877,3.762c-4.269-2.444-9.024-3.762-13.877-3.762c-8.559,0-16.967,4.064-23.071,11.15
      c-6.308,7.324-9.134,16.813-7.753,26.035c0.014,0.093,0.029,0.185,0.045,0.277l26.897,153.12l-18.714-0.006
      c-5.705-36.312-24.54-63.911-40.091-86.696c-18.68-27.382-34.812-51.029-34.812-93.78c0-57.347,49.968-104.001,111.388-104.001
      c61.419,0,111.388,46.654,111.388,104.001C341.169,183.337,325.05,206.987,306.371,234.396z M229.768,293.268l-22.669-129.053
      c-0.537-3.92,1.643-6.947,2.612-8.071c1.783-2.069,4.208-3.406,6.18-3.406c2.472,0,4.369,1.851,5.28,2.953
      c2.118,2.566,5.27,4.051,8.597,4.051c3.326,0,6.479-1.485,8.597-4.051c0.912-1.103,2.809-2.953,5.281-2.953
      c2.189,0,4.653,1.286,6.43,3.354c1.133,1.319,2.936,4.115,2.365,8.103L229.768,293.268z"
    />
  </svg>
)

const Avatar = ()=> (
  <svg className={css.avatar} viewBox="0 0 384 384">
    <circle cx="192" cy="56" r="40"/>
      <path 
        d="M192,112c30.88,0,56-25.12,56-56S222.88,0,192,0s-56,25.12-56,56S161.12,112,192,112z M192,32
        c13.232,0,24,10.768,24,24s-10.768,24-24,24s-24-10.768-24-24S178.768,32,192,32z"
      />
      <path 
        d="M248.84,238.312c2.304,1.152,4.744,1.688,7.152,1.688c5.864,0,11.52-3.24,14.328-8.848
        c3.952-7.904,0.744-17.512-7.16-21.472L208,182.112V152c0-0.048-0.016-0.088-0.016-0.128c-0.008-0.72-0.112-1.424-0.216-2.136
        c-0.048-0.344-0.048-0.704-0.12-1.04c-0.12-0.552-0.328-1.072-0.496-1.6c-0.144-0.456-0.248-0.936-0.44-1.376
        c-0.184-0.432-0.456-0.832-0.68-1.248c-0.256-0.48-0.48-0.984-0.784-1.44c-0.384-0.568-0.856-1.08-1.32-1.6
        c-0.216-0.248-0.4-0.528-0.632-0.76c-0.368-0.368-0.8-0.664-1.2-0.992c-0.4-0.328-0.776-0.688-1.208-0.984
        c-0.296-0.2-0.64-0.336-0.952-0.52c-0.616-0.352-1.216-0.712-1.88-0.976c-0.04-0.016-0.072-0.04-0.112-0.056l-40-16
        c-5.944-2.376-12.728-0.976-17.256,3.544l-16,16c-3,3-4.688,7.072-4.688,11.312v64c0,8.832,7.168,16,16,16s16-7.168,16-16v-57.368
        l3.848-3.856L176,162.832v89.392l-54.312,108.624c-3.952,7.904-0.744,17.512,7.16,21.472c2.296,1.144,4.736,1.68,7.144,1.68
        c5.864,0,11.52-3.24,14.328-8.848l44.112-88.232L216,317.128V368c0,8.832,7.168,16,16,16s16-7.168,16-16v-56
        c0-3.336-1.04-6.584-2.984-9.304L208,250.872v-32.984L248.84,238.312z"
      />
    </svg>
)

const GameRules = ()=> (
  <>
    <p>
      The goal is to reach the exit (located on the bottom-right corner), with all the 
      lights turned on.
    </p>
    <p>
      Lights will turn on/off when you step into the room
    </p>
    <p>
      You may use the arrow keys to play, or you can activate the arrow buttons to play 
      on mobile devices.
    </p>
  </>
)

const initialState = ()=> (new Array(15)).fill(false).map(()=> Math.random() > 0.5)

const LightSwitch = ()=> {

  const [lights, setLights] = useState(initialState())
  const [position, setPosition] = useState(1)
  const [exit, setExit] = useState(false)
  const [success, setSuccess] = useState(false)
  const [visibleArrows, setVisibleArrows] = useState(false)

  const handleKeyDown = useCallback(({ key })=> {
    if (success) return
    if (!['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(key)) return
    let target_position = position
    if (key === 'ArrowLeft') {
      target_position = position - 1
      if (target_position % 4 === 0) return 
      if (target_position === 1) return setPosition(1)
    }
    else if (key === 'ArrowRight') {
      target_position = position + 1
      if (target_position % 4 === 1) return 
    }
    else if (key === 'ArrowUp') {
      target_position = position - 4
      if (target_position < 1) return
      if (target_position === 1) return setPosition(1)
    }
    else if (key === 'ArrowDown') {
      target_position = position + 4
      if (target_position === 20) return setExit(true)
      if (target_position > 16) return
    }

    // Apply changes
    setExit(false)
    setPosition(target_position)
    setLights(current_lights=> ([
      ...current_lights.slice(0, target_position-2),
      !current_lights[target_position-2],
      ...current_lights.slice(target_position-1),
    ]))
  }, [position, success])

  useEffect(()=> {
    if (!exit) return
    if (!lights.includes(false)) setSuccess(true)
  }, [exit, lights])

  useEffect(() => {
    const listener = e=> { 
      e.preventDefault()
      handleKeyDown(e)
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [position, success, handleKeyDown])

  const handleResetBoard = ()=> {
    setLights(initialState())
    setPosition(1)
    setExit(false)
    setSuccess(false)
  }

  const handleButtonPress = key => ()=> handleKeyDown({ key })

  const handleToggleControls = ()=> setVisibleArrows(c=> !c)

  return (
    <>
      <GameHeader 
        title='Light Switch' 
        rules={<GameRules />}
        options={[
          {
            label: visibleArrows ? 'Hide arrows' : 'Show arrows',
            onClick: handleToggleControls,
            description: 'You may use the arrow buttons to play on mobile devices'
          }
        ]}
        onReset={handleResetBoard}
      />
      <div className={css.main}>
        <div className={css.controls}>
          <div 
            className={css.notification}
            data-status={exit ? 'completed' : 'playing'}
            data-result={success ? 'success' : 'failure'}
          >
            {!exit && 'Now playing...'}
            {exit && success && 'You did it! Congratulations!'}
            {exit && !success && 'Woops! Some lights are not on! Keep trying'}
          </div>
          {visibleArrows && (
            <div className={css.arrows}>
              <button className={css.arrow} onClick={handleButtonPress('ArrowLeft')}>←</button>
              <div className={css.vertical}>
                <button className={css.arrow} onClick={handleButtonPress('ArrowUp')}>↑</button>
                <button className={css.arrow} onClick={handleButtonPress('ArrowDown')}>↓</button>
              </div>
              <button className={css.arrow} onClick={handleButtonPress('ArrowRight')}>→</button>
            </div>
          )}
        </div>
        <div className={css.board}>
          <div className={css.room} data-light='off' data-room='1'>
            {position === 1 && <Avatar />}
          </div>
          {lights.map((light, i)=> (
            <div 
              key={i+2} 
              data-room={i+2}
              data-light={light ? 'on' : 'off'}
              className={css.room}
            >
              {position === (i+2) ? <Avatar /> : <Lightbulb />}
            </div>
          ))}
        </div>
      </div>
    </>
  )

}

export default LightSwitch