import { useCallback, useEffect, useMemo, useState }  from 'react'
import GameHeader                                     from '../../components/GameHeader/GameHeader'
import useAnimationFrame                              from '../../hooks/useAnimationFrame'
import ResourceGroup                                  from './resources/ResourceGroup/ResourceGroup'
import { 
  getTeamMemberCost,
  getTeamEfficiency,
  getTeamMembers,
  getResearchCost,
  getPerkCost,
  getTeamSize }                                       from './resources/company'
import HireTalent                                     from './resources/HireTalent/HireTalent'
import HostEvent                                      from './resources/HostEvent/HostEvent'
import UpdateCompany                                  from './resources/UpdateCompany/UpdateCompany'
import ResultPanel                                    from './resources/ResultPanel/ResultPanel'

import employeesAvailable                             from './resources/data/employees.json'
import researchAvailable                              from './resources/data/research.json'
import perksAvailable                                 from './resources/data/perks.json'
import eventsAvailable                                from './resources/data/events.json'
import trainingAvailable                              from './resources/data/training.json'
import TrainTalent                                    from './resources/TrainTalent/TrainTalent'

import css                                            from './LinesOfCode.module.css'

const GameRules = ()=> (
  <>
    <p>
      Make the most complex app ever!
      <br />
      Hire a few interns and start writing code. As your app scales you can hire more engineers to produce code faster.
      <br />
      Keep them happy! Acquire perks & benefits to increase their productivity.
      <br />
      Research new technologies to unlock new employees suitable for hire!
      <br />
      <strong>Pro Tip:</strong> Hold Shift to hire multiple employees at once.
    </p>
  </>
)

const CompanyTeam = ({ title, group, team, perks, linesOfCode, isUnlocked, isExpanded, onExpand, onHire })=> (
  <ResourceGroup 
    title={title}
    total={getTeamMembers({ group, team })}
    labelHint='employees'
    isExpanded={isExpanded}
    isUnlocked={isUnlocked}
    onClick={onExpand}
  >
    <>
      {employeesAvailable.filter(e=> e.team === group).map(({ id, name, description })=> (
        <HireTalent
          key={id}
          name={name}
          description={description}
          current={team[id]}
          cost={getTeamMemberCost({ type: id, team, perks })}
          lines={linesOfCode}
          onClick={(e)=> onHire({ type: id, max: e.shiftKey })}
        />
      ))}
    </>
  </ResourceGroup>
)

const LinesOfCode = ()=> {

  const [linesOfCode, setLinesOfCode] = useState(50)

  const [isCheatingEnabled, setCheatingEnabled] = useState(true)

  const [team, setTeam] = useState({ interns: 1 })
  const [research, setResearch] = useState([])
  const [perks, setPerks] = useState([])
  const [events, setEvents] = useState([])

  const [activeGroup, setActiveGroup] = useState('')

  useEffect(()=> {
    if (isCheatingEnabled) return
    setTimeout(()=> setCheatingEnabled(true), 10000)
  }, [isCheatingEnabled])

  const efficiency = useMemo(()=> getTeamEfficiency({ team, perks }), [team, perks])

  const addLines = useCallback((time)=> {
    const increase = time * efficiency / 1000
    setLinesOfCode(c=> c + increase)
  }, [efficiency])

  useAnimationFrame(addLines)

  const handleResetGame = ()=> {
    setLinesOfCode(50)
    setTeam({ interns: 1 })
    setResearch([])
    setPerks([])
    setEvents([])
    setActiveGroup('')
  }

  const handleCheatGame = ()=> {
    setLinesOfCode(c=> 10*c)
    setCheatingEnabled(false)
  }

  const handleHireTeamMember = ({ type, max=false })=> {
    if (!max) {
      const cost = getTeamMemberCost({ type, team, perks })
      if (linesOfCode < cost) return
      setLinesOfCode(currentLines=> currentLines-cost)
      setTeam(currentTeam=> ({
        ...currentTeam,
        [type]: (currentTeam[type] || 0) + 1
      }))
    }
    else {
      let totalCost = 0
      let totalHires = 0
      while (true) {
        const newTeam = { 
          ...team, 
          [type]: (team[type] || 0) + totalHires
        }
        const cost = getTeamMemberCost({ type, team: newTeam, perks })
        if (linesOfCode < (totalCost + cost)) break
        totalCost += cost
        totalHires++
      }
      setLinesOfCode(currentLines=> currentLines-totalCost)
      setTeam({
        ...team,
        [type]: (team[type] || 0) + totalHires
      })
    }
  }

  const handleResearch = (research)=> {
    const cost = getResearchCost(research)
    if (linesOfCode < cost) return
    setLinesOfCode(currentLines=> currentLines-cost)
    setResearch(r=> [...r, research])
  }

  const handlePerk = (perk)=> {
    const cost = getPerkCost(perk.id)
    if (linesOfCode < cost) return
    setLinesOfCode(currentLines=> currentLines-cost)
    setPerks(p=> [...p, perk])
  }

  const handleEvent = (event)=> {
    const fullEvent = eventsAvailable.find(e=> e.id === event)
    setEvents(e=> [...e, event])
    setLinesOfCode(currentLines=> currentLines + fullEvent.reward)
  }

  const handleTraining = ({ from, to, cost })=> {
    if (!team[from]) return
    if (linesOfCode < cost) return
    setLinesOfCode(currentLines=> currentLines-cost)
    const promotedEmployees = Math.floor(team[from] / 2)
    setTeam(currentTeam=> ({
      ...currentTeam,
      [from]: currentTeam[from] - promotedEmployees,
      [to]: (currentTeam[to] || 0) + promotedEmployees
    }))
  }

  return (
    <>
      <GameHeader 
        title='Lines of Code' 
        rules={<GameRules />}
        options={[
          {
            label: 'Cheat!',
            onClick: handleCheatGame,
            disabled: !isCheatingEnabled,
            description: 'It\'s not how you are supposed to play the game, but it\'s faster.'
          }
        ]}
        onReset={handleResetGame}
      />
      <div className={css.main}>
        <div className={css.info}>
          <ResultPanel 
            lines={linesOfCode} 
            efficiency={efficiency}
          />
        </div>
        <div className={css.office}>
          <ResourceGroup 
            title='Events'
            isExpanded={activeGroup === 'events'}
            total={events.length}
            labelHint='organized'
            onClick={()=> setActiveGroup(g=> g === 'events' ? '' : 'events')}
          >
            {eventsAvailable.map(({ id, name, teamSize })=> (
              <HostEvent
                key={id}
                name={name}
                isCompleted={events.includes(id)}
                isAvailable={getTeamSize({ team }) >= teamSize}
                lines={linesOfCode}
                onClick={()=> handleEvent(id)}
              />
            ))}
          </ResourceGroup>
          <ResourceGroup 
            title='Research'
            total={research.length}
            isExpanded={activeGroup === 'research'}
            labelHint='completed'
            onClick={()=> setActiveGroup(g=> g === 'research' ? '' : 'research')}
          >
            <>
              {researchAvailable.map(({ id, name })=> (
                <UpdateCompany
                  key={id}
                  name={name}
                  isCompleted={research.includes(id)}
                  cost={getResearchCost(id)}
                  lines={linesOfCode}
                  onClick={()=> handleResearch(id)}
                />
              ))}
            </>
          </ResourceGroup>
          <ResourceGroup 
            title='Office Perks'
            labelHint='purchased'
            total={perks.filter(p=> p.group === 'office').length}
            isExpanded={activeGroup === 'office'}
            onClick={()=> setActiveGroup(g=> g === 'office' ? '' : 'office')}
          >
            <>
              {perksAvailable.filter(p=> p.group === 'office').map((availablePerk)=> (
                <UpdateCompany
                  key={availablePerk.id}
                  name={availablePerk.name}
                  isCompleted={perks.find(p=> p.id === availablePerk.id)}
                  cost={getPerkCost(availablePerk.id)}
                  lines={linesOfCode}
                  onClick={()=> handlePerk(availablePerk)}
                />
              ))}
            </>
          </ResourceGroup>
          <ResourceGroup 
            title='Desktop Perks'
            total={perks.filter(p=> p.group === 'personal').length}
            labelHint='purchased'
            isExpanded={activeGroup === 'personal'}
            onClick={()=> setActiveGroup(g=> g === 'personal' ? '' : 'personal')}
          >
            <>
              {perksAvailable.filter(p=> p.group === 'personal').map((availablePerk)=> (
                <UpdateCompany
                  key={availablePerk.id}
                  name={availablePerk.name}
                  isCompleted={perks.find(p=> p.id === availablePerk.id)}
                  cost={getPerkCost(availablePerk.id)}
                  lines={linesOfCode}
                  onClick={()=> handlePerk(availablePerk)}
                />
              ))}
            </>
          </ResourceGroup>
          <ResourceGroup 
            title='Office Benefits'
            labelHint='provided'
            total={perks.filter(p=> p.group === 'extra').length}
            isExpanded={activeGroup === 'extra'}
            onClick={()=> setActiveGroup(g=> g === 'extra' ? '' : 'extra')}
          >
            <>
              {perksAvailable.filter(p=> p.group === 'extra').map((availablePerk)=> (
                <UpdateCompany
                  key={availablePerk.id}
                  name={availablePerk.name}
                  isCompleted={perks.find(p=> p.id === availablePerk.id)}
                  cost={getPerkCost(availablePerk.id)}
                  lines={linesOfCode}
                  onClick={()=> handlePerk(availablePerk)}
                />
              ))}
            </>
          </ResourceGroup>
          <ResourceGroup 
            title='Training'
            isExpanded={activeGroup === 'training'}
            onClick={()=> setActiveGroup(g=> g === 'training' ? '' : 'training')}
          >
            <>
              {trainingAvailable.map((training)=> (
                <TrainTalent
                  key={training.id}
                  name={training.name}
                  cost={training.cost}
                  description={training.description}
                  lines={linesOfCode}
                  onClick={()=> handleTraining(training)}
                />
              ))}
            </>
          </ResourceGroup>
        </div>
        <div className={css.team}>
          <CompanyTeam
            title='Core Team'
            group='core'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked
            isExpanded={activeGroup === 'core'}
            onExpand={()=> setActiveGroup(g=> g === 'core' ? '' : 'core')}
            onHire={handleHireTeamMember}
          />
          <CompanyTeam
            title='Code Experts'
            group='experts'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked
            isExpanded={activeGroup === 'experts'}
            onExpand={()=> setActiveGroup(g=> g === 'experts' ? '' : 'experts')}
            onHire={handleHireTeamMember}
          />
          <CompanyTeam
            title='Animal Kingdom'
            group='animals'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked={research.includes('animals')}
            isExpanded={activeGroup === 'animals'}
            onExpand={()=> setActiveGroup(g=> g === 'animals' ? '' : 'animals')}
            onHire={handleHireTeamMember}
          />
          <CompanyTeam
            title='Managers'
            group='management'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked={research.includes('management')}
            isExpanded={activeGroup === 'management'}
            onExpand={()=> setActiveGroup(g=> g === 'management' ? '' : 'management')}
            onHire={handleHireTeamMember}
          />
          <CompanyTeam
            title='AI Coders'
            group='ml'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked={research.includes('ml')}
            isExpanded={activeGroup === 'ml'}
            onExpand={()=> setActiveGroup(g=> g === 'ml' ? '' : 'ml')}
            onHire={handleHireTeamMember}
          />
          <CompanyTeam
            title='Aliens'
            group='aliens'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked={research.includes('aliens')}
            isExpanded={activeGroup === 'aliens'}
            onExpand={()=> setActiveGroup(g=> g === 'aliens' ? '' : 'aliens')}
            onHire={handleHireTeamMember}
          />
          <CompanyTeam
            title='Dark Creatures'
            group='dark'
            team={team}
            perks={perks}
            linesOfCode={linesOfCode}
            isUnlocked={research.includes('dark')}
            isExpanded={activeGroup === 'dark'}
            onExpand={()=> setActiveGroup(g=> g === 'dark' ? '' : 'dark')}
            onHire={handleHireTeamMember}
          />
        </div>
      </div>
    </>
  )
}

export default LinesOfCode